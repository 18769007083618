import React, { useState, useEffect, useRef } from 'react';
import { doc, addDoc, collection, updateDoc, arrayUnion, query, where, getDocs, getDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import { auth, db } from '../../firebase';
import Events from './Events';
import './addeventform.css';
import Swal from 'sweetalert2';
import { FaEnvelopeOpenText } from 'react-icons/fa'
import { TbMailbox } from 'react-icons/tb'
import emailjs from '@emailjs/browser';
import { propsDate } from './dateformat';
import { BiHide } from 'react-icons/bi';



export default function AddEventForm ({ colors }) {
    // const user2 = currentUser.currentUser.uid // development
    // const userEmail = currentUser.currentUser?.email // development
    const userEmail = auth.currentUser.email // production version
    const user2 = auth.currentUser.uid // production version


    const [eventNameDate, setEventNameDate] = useState({});
    const [eventOwner] = useState(userEmail)
    const [eventRef] = useState(user2)
    const [didSubmit, setDidSubmit] = useState(false)
    const [eventParticipants, setEventParticipants] = useState([userEmail])
    const [hiddenEvents, setHiddenEvents] = useState([])
    const eventNameRef = useRef();
    const eventDateRef = useRef();
    const eventDateEndRef = useRef();


    /////////////check events//////
    const [inviteData, setInviteData] = useState([])
    const [appendedInvite, setAppendedInvite] = useState([])

    ///////////////////////////// fetch user events ///////////////////////////

    const [docData, setDocData] = useState([])
    const q1 = query(collection(db, "events"), where("eventParticipants", "array-contains", userEmail));
    const queryInvites = query(collection(db, "invites"), where("invitee", "==", userEmail))


    useEffect(() => {

        const getUserEvents = async () => {
            let list = [];

            try {
                const querySnapshot = await getDocs(q1);
                querySnapshot.forEach((doc) => {
                    list.push({id: doc.id, ...doc.data(), appendedInvite})
                });
                setDocData(list)
            } catch (error) {
                console.log(error)
            }
        }
        getUserEvents()

        const getHiddenEventsList = async () => {
            const arr = [];
            const docRef = doc(db, 'users', user2)
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                // console.log(docSnap.data());

                    arr.push(
                        docSnap.data()?.hideEventsList
                    )
                    setHiddenEvents(...arr)

            }
        }
        getHiddenEventsList()

        const unsubscribe = onSnapshot(queryInvites, querySnapshot => {
            let invites = [];
            querySnapshot.docs.forEach((doc) => {
                invites.push({id: doc.id, ...doc.data()})
            });
            setInviteData(invites)

        }, error => console.log(error))

        return () => {
            unsubscribe()
        }

    }, [didSubmit])

    const handleEventNameDate = e => {
        setEventNameDate((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
        }));
    }


    /////////////////////////////////

    const handleSubmit = async e => {
        e.preventDefault();
        if (eventNameRef.current.value === '' || eventDateRef.current.value === '' || eventDateEndRef.current.value === '') {
            return Swal.fire({
                title: '<span style="font-style: italic;">You must provide <span style="text-decoration: underline;">event names & start-end, dates!</span></span>',
                confirmButtonColor: `${colors?.primary ? colors?.primary : ''}`
            })
        }
        try {
            const docRef = await addDoc(collection(db, 'events'), {
                events: {
                    eventName: eventNameDate.eventname,
                    eventDate: eventNameDate.eventdate,
                    eventEndDate: eventNameDate.enddate,
                    eventOwner: eventOwner,
                    eventRef: eventRef,
                },
                eventParticipants,
            });

            document.querySelectorAll('input').forEach(input => input.value = '');
            setDidSubmit(true)
        } catch (error) {
            console.log(error);
        }
    }

    const handleInviteClick = async () => {
        const eventNames = docData.map(i => i.events.eventName)

        const { value: event } = await Swal.fire({
            title: 'Please select event to share:',
            text: 'Note: this will invite the user via their dashboard invites, as well as (optionally) send them an automated email invite.',
            confirmButtonColor: '',
            input: 'select',
            inputOptions: {
                'Events':
                    {...eventNames}
            },
            inputPlaceholder: 'Your events...',
        })
        if (event) {
            const eName = docData[event].events.eventName
            const eDate = docData[event].events.eventDate
            const eventId = docData[event].id
            const { value: inviteeEmail } = await Swal.fire({
                title: `Share event "${eName}" with:`,
                confirmButtonColor: '',
                input: 'email',
                inputLabel: '',
                inputPlaceholder: 'Invitee\'s email'
            })
            if (inviteeEmail) {

                let inviteeE = inviteeEmail.trim().toLowerCase();
                let invLink = `https://claims.gifts/dashboard/${eventId}`

                let tempParam = {
                    from_name: userEmail,
                    event_name: eName,
                    event_date: eDate,
                    to_email: inviteeE,
                    invite_link: invLink,
                }
                const templateID = 'template_db6c98h';
                const publicKey = 'C0U6FhGhn-2kWm9SD';
                const handleEmail = async () => {
                    await emailjs.send('default_service', templateID, tempParam, publicKey)
                        .then((res) => console.log('Success ', res.status, res.text))
                        .catch(err => console.log(err))
                }

                const { value: acceptEmail } = await Swal.fire({
                    title: `Invitation sent to ${inviteeEmail}'s 'message invite center'.`,
                    confirmButtonColor: '',
                    text: 'Send an automated email to this user regarding their invitation?',
                    input: 'checkbox',
                    inputValue: 1,
                })
                if (acceptEmail) {
                    Swal.fire({
                        title: `Event invitation sent to users dashboard invites and to email ${inviteeEmail}!`,
                        confirmButtonColor: ''
                    })
                    .then(() => addDoc(collection(db, "invites"), {
                        invitee: inviteeEmail.trim().toLowerCase(),
                        event: eName,
                        invitedBy: userEmail,
                        eventDate: eDate,
                        eventId: eventId,
                    }))

                    .then(() => handleEmail())
                    .catch((err) => console.log(err))
                } else {
                    addDoc(collection(db, "invites"), {
                        invitee: inviteeEmail.trim().toLowerCase(),
                        event: eName,
                        invitedBy: userEmail,
                        eventDate: eDate,
                        eventId: eventId,
                    })
                }

            }

        }

    }

    const handleCheckEventClick = () => {
        const filtAcceptedInvites = inviteData.filter(i => !i.acceptedInvite === true)

        const mapInvites = filtAcceptedInvites.map(i => (`Event: "${i.event}" | From: "${i.invitedBy}"`))
        if (filtAcceptedInvites.length === 0) return Swal.fire({title: '<b style="font-style: italic">No invites available!</b>', confirmButtonColor: ''})

        const confirmInvite = async () => {

            const { value: choice } = await Swal.fire({
                title: `Invitations:`,
                input: 'select',
                inputOptions: {
                    'Invitations': {
                        ...mapInvites
                    }
                },
                confirmButtonColor: '',
                showCancelButton: true,
            })
            if (choice) {

                const eventConfirmed = filtAcceptedInvites[choice]
                const eventConfirmedId = filtAcceptedInvites[choice].eventId
                const inviteDocId = filtAcceptedInvites[choice].id

                const fixedDate = eventConfirmed.eventDate.slice(5) + '-' + eventConfirmed.eventDate.slice(0, 4)

                Swal.fire({
                    title: `You have been been added to "The List" \nFor event: \n"${eventConfirmed.event}" \n${fixedDate}`,
                    confirmButtonColor: ''
                })
                // .then(() => setAgreedEvent(true))
                .then(() => updateDoc(doc(db, 'invites', inviteDocId),
                { acceptedInvite: true }
                ))
                .catch((err) => console.log(err))

                const addMeToEvent = async () => {
                    let list = []
                    const docRef = doc(db, "events", eventConfirmedId)
                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        // console.log('docSnap .data()', docSnap.data())
                        list.push(docSnap.data().eventParticipants)
                        setEventParticipants(prev => prev, ...list)
                    } else {
                        console.log('No such doc!');
                    }
                    console.log(list)
                }
                addMeToEvent()

                const updateEventPartici = async () => {
                    const updatedParticiRef = doc(db, "events", eventConfirmedId)
                    await updateDoc(updatedParticiRef, {
                        eventParticipants: arrayUnion(...eventParticipants)
                    })
                }
                updateEventPartici()

                const getSingleDoc = async () => {
                    let arr = [];
                    const singleDocRef = doc(db, "events", eventConfirmedId)
                    const docSnap = await getDoc(singleDocRef)
                    if (docSnap.exists()) {
                        // console.log(docSnap.data())
                        arr.push({id: docSnap.id, ...docSnap.data()})
                        setAppendedInvite(docSnap.data())
                        setDocData(prev => [...prev, {id: docSnap.id, ...docSnap.data()}])
                    }
                }
                getSingleDoc()
            }
        }
        confirmInvite()
    }

    const handleRemoveEvent = async (e) => {
        e.preventDefault()
        const userOwnedEvents = docData.filter(i => i.events.eventOwner === userEmail)
        const eventsToRemove = userOwnedEvents.map(i => i.events.eventName)
        const eventsToRemoveId = userOwnedEvents.map(i => i.id)


        const { value: selection } = await Swal.fire({
            title: `Please select event to remove!`,
            html: 'Note: you can only remove events that <b style="font-style: italic;">you</b> have created...',
            confirmButtonColor: '',
            input: 'select',
            inputOptions:
                {...eventsToRemove},
            inputPlaceholder: `Events to remove...`
        })
        if (selection) {

            Swal.fire({
                title: 'Removing this event cannot be undone!',
                text: 'Other users that are participating in this event will be affected!',
                icon: 'warning',
                iconColor: 'crimson',
                confirmButtonText: 'Yes, Proceed',
                confirmButtonColor: 'pink',
                showCancelButton: true,
                cancelButtonColor: '',
            })
            .then(result => {
                if (result.isConfirmed) {
                    deleteDoc(doc(db, 'events', eventsToRemoveId[selection]))
                } else if (result.isDenied) {
                    return
                }
            })
            .then(() => setDidSubmit(prev => !prev))
            .catch(err => console.log(err))

        }
    }
    const handleHide = async () => {
        const eventsToHide = docData.map(i => i.events.eventName)
        const eventsToHideRef = docData.map(i => i.id)

        if (!docData.length) {
            Swal.fire({
                title: 'You have no events to hide!',
            });
            return
        }

        let c = ['Hide', 'Unhide']

        const { value: choice } = await Swal.fire({
            title: 'Please select if you are hiding or unhiding an event...',
            confirmButtonColor: '',
            showCancelButton: true,
            input: 'radio',
            inputValue: '0',
            inputOptions: {
                ...c
            }
        })
        if (choice === '0') { // hide
            const { value: hideSelect } = await Swal.fire({
                title: 'Please select which event to hide:',
                input: 'select',
                confirmButtonColor: '',
                inputOptions: {
                    ...eventsToHide,
                },
                inputPlaceholder: '<b>Events to hide...</b>'
            })
            if (hideSelect) {
                const chosen = eventsToHide[hideSelect]
                const chosenRef = eventsToHideRef[hideSelect]

                if (hiddenEvents) {
                    setHiddenEvents(prev => [...prev, { choice: chosen, choiceRef: chosenRef }])
                } else {
                    setHiddenEvents([{ choice: chosen, choiceRef: chosenRef }])
                }

                const docRef = doc(db, 'users', user2)
                await updateDoc(docRef, {
                    hideEventsList: arrayUnion({choice: chosen, choiceRef: chosenRef})
                })
                setDidSubmit(prev => !prev)
            }
        } else if (choice === '1') { // unhide
            let hidden = hiddenEvents.map(i => i.choice)
            const { value: unhideChoice } = await Swal.fire({
                title: 'Select which event to unhide...',
                showCancelButton: true,
                confirmButtonColor: '',
                input: 'select',
                inputOptions: {
                    ...hidden
                }
            })
            if (unhideChoice) {
                let updatedHideList = hiddenEvents.filter(e => !hiddenEvents[unhideChoice].choiceRef.includes(e.choiceRef))
                const docRef = doc(db, 'users', user2)
                await updateDoc(docRef, {
                    hideEventsList: updatedHideList
                })
                setHiddenEvents(updatedHideList)
            }
        }
    }

    const filterHiddenEvents = (docData, hiddenEvents) => {
    let hideChoice = hiddenEvents?.map(i => i?.choiceRef)
       return docData.filter(e => !hideChoice?.includes(e.id))
    }

    return (
        <>
        <hr />
            <div className="sm:w-1/2 mx-auto">

                <form onSubmit={handleSubmit}>
                    <div className='flex flex-col items-center justify-center mx-4'>
                    <h3 className='text-3xl sm:text-4xl italic my-4'>Add New Event...</h3>
                    <div>
                        <label htmlFor='eventname'>Event Name:</label>
                            <input className="input my-2 border border-primary w-full" name="eventname" ref={eventNameRef} placeholder='e.g. potluck' onChange={handleEventNameDate} />
                        <label htmlFor='eventdate'>Start Date:</label>
                            <input className="input my-2 border border-primary w-full" type="date" ref={eventDateRef} name="eventdate" placeholder='event date' onChange={handleEventNameDate} />
                        <label htmlFor='enddate'>End Date:</label>
                            <input className="input my-2 border border-primary w-full" type="date" ref={eventDateEndRef} name="enddate" placeholder='event date' onChange={handleEventNameDate} />
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <button className="btn btn-primary hover:bg-secondary mx-1 my-2" type="submit" onClick={() => setDidSubmit(false)}>Add Event</button>
                        <button className="btn btn-warning hover:bg-error mx-1 my-2" type="button" name='removeBtn' onClick={e => handleRemoveEvent(e)}>Remove Event</button>
                    </div>
                    <div id="invitesTainer" className='mx-4'>

                        <div className='chkInvHide my-2 w-fit'>Check Event Invites &nbsp;<a className='hover:cursor-pointer' onClick={handleCheckEventClick}><TbMailbox className='text-primary hover:text-secondary hover:scale-[115%] hover:duration-200 ease-in-out' size={'35px'} /></a></div>
                        <div className='chkInvHide my-2 w-fit'>Invite User to attend &nbsp;<a className='hover:cursor-pointer' onClick={handleInviteClick}><FaEnvelopeOpenText className='text-primary hover:text-secondary hover:scale-[115%] hover:duration-200 ease-in-out' size={'35px'} /></a></div>
                        {<div className={`chkInvHide my-2 w-fit`}>Hide Event&nbsp;<a className='hover:cursor-pointer' onClick={handleHide}><BiHide className={`${!docData.length ? 'text-neutral' : 'text-primary hover:text-secondary hover:scale-[115%] hover:duration-200 ease-in-out'} `} size={'35px'} /></a></div>}
                    </div>
                </form>
            </div>
                <Events data={docData && filterHiddenEvents(docData, hiddenEvents)}   /> {/* lastly: send event blob here*/} {/* docData was the original */}
        </>
    )
}