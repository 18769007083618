import { collection, setDoc, updateDoc, doc, arrayUnion, where, query, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { BsFileBreakFill, BsInfoCircle } from 'react-icons/bs';
import { HiOutlineExternalLink } from 'react-icons/hi';
import Swal from 'sweetalert2';
import { db } from '../../firebase';
import { GrEdit } from 'react-icons/gr'
import { MdCallSplit } from 'react-icons/md';
import './addgiftform.css';
import './gifts.css';
import blankProfilePic from '../../images/blank-profile.webp'



export default ({ giftArray, originalGiftArray, user, inFocus }) => {
    // {giftArray && giftArray.map(i => (<ul key={i.giftName}><li><b>Gift: </b>{i.giftName}</li><li><b>Link: </b>{i.giftLink}</li><li><b>Requestor: </b>{i.requestor}</li><b>Claimed: </b><li>{i.claimed ? "claimed" : i.requestor === user.email ? 'Unavailable to requestor!' : "not claimed"}</li></ul>))}
    const [checked, setChecked] = useState(false)
    const [notChecked, setNotChecked] = useState(true)
    const [isClaimed, setIsClaimed] = useState(Boolean)
    const [toggle, setToggle] = useState(true)
    const [splittable, setSplittable] = useState(false)
    const [show, setShow] = useState(false)
    const [wasEdited, setWasEdited] = useState(false)
    const [newName, setNewName] = useState('')
    const [newLink, setNewLink] = useState('')
    const [claimeeArray, setClaimeeArray] = useState([])
    const [screenWidth, setScreenWidth] = useState(Boolean)


    // console.log(giftArray[0]?.giftLink);

    const location = useLocation()
    const eventId = location.pathname?.split("/")[3]
    const eventRef = doc(db, 'events', eventId)

    let inc = 0;


    // console.log(location);

    useEffect(() => {
        // const updateClaimed = async () => {
        //     const docRef = doc(db, "events", eventId)
        //     await updateDoc(docRef, {
        //         gifts: [...giftArray]
        //     })
        // }
        // updateClaimed()
        // console.log(inFocus);


        // const unsub2 = onSnapshot(doc(db, "events", eventId), doc => {
        //     console.log('current doc data ', doc.data());
        //     const arr = [];

        //     arr.push({id: doc.id, ...doc.data().gifts})

        //     let format = Object.values(arr[0])
        //     setOriginalGiftArray(format.slice(0, -1))
        // });

        // setOriginalGiftArray(giftArray)
        setClaimeeArray(giftArray)
        setScreenWidth(window.visualViewport.width)

        // return () => {
        //     unsub2()
        // }

    }, [isClaimed, wasEdited, screenWidth])

    // console.log(giftArray);
    // console.log(screenWidth);

    const handleEdit = async (e, index) => {
        // console.log(e.target);
        e.preventDefault()

        let name = giftArray[index].giftName
        let link = giftArray[index].giftLink
        let cost = giftArray[index]?.giftCost

        const { value : editedText } = await Swal.fire({
            title: 'Edit values...',
            confirmButtonColor: '',
            html: ` <div style="display: flex; align-items:center; justify-content:center; flex-direction: column;">
                    <label>Item Name</label><input id="textEditIn1" placeholder="${name}" />
                    <label>Item Link</label><input id="textEditIn2" placeholder="${link !== '' ? link : ''}" />
                    <label>Item Cost</label><input id="textEditIn3" placeholder="${cost ? cost : '$'}" />
                    </div>`,
            focusConfirm: false,
            preConfirm: () => {
                return [
                    document.getElementById('textEditIn1').value,
                    document.getElementById('textEditIn2').value,
                    document.getElementById('textEditIn3').value
                ]
            }

        })
        if (editedText) {

            // let updatedGiftArr = [...giftArray] // updated as in filtered as well
            // let unUpdatedGiftArr = [...untouchedGiftArray]
            let updatedGiftArr = [...giftArray, ...originalGiftArray]
            let updatedArr = [...new Set(updatedGiftArr)]
            updatedGiftArr[index].giftName = editedText[0]
            updatedGiftArr[index].giftLink = editedText[1]
            updatedGiftArr[index].giftCost = editedText[2]
            if (editedText[0] === '') {
                updatedGiftArr[index].giftName = name
            }
            if (editedText[1] === '') {
                updatedGiftArr[index].giftLink = link
            }
            if (editedText[2] === '') {
                updatedGiftArr[index].giftCost = cost || '0'
            }

            await updateDoc(eventRef, {
                gifts: (
                    updatedArr
                )
            })
            // setWasEdited(prev => !prev)
        }

    }

    // const updateClaimed = async () => {          OLD & BAD

    //     const docRef = doc(db, "events", eventId)
    //     await updateDoc(docRef, {
    //         gifts: [...giftArray]
    //     })
    // }
    const updateClaimed = async () => {
        const docRef = doc(db, "events", eventId)
        // await updateDoc(docRef, {
        //     gifts: [...giftArray]
        // }) // Bad!~ this took the filtered array and updated the entire array based on the filtered array!
        let combinedArrays = [...giftArray, ...originalGiftArray] // the filterable array and the ENTIRE (original) gift array
        let filterOutPriorGiftStatus = [...new Set(combinedArrays)]
        await updateDoc(docRef, {
            gifts: [...filterOutPriorGiftStatus]
        });
    }

    const handleClaim = async (e) => {
        // console.log(e.target.id)
            return Swal.fire({
                title: 'Would you like to claim this gift?',
                confirmButtonColor: '',
                showCancelButton: true,
                cancelButtonColor: 'gray',
            })
            .then( async (result) => {
            if (result.isConfirmed) {

                setChecked(current => !current)

                giftArray[e.target.id].claimed = true
                giftArray[e.target.id].claimee = user.email

               updateClaimed()
               setIsClaimed(true)
            } else {
                return
            }
            if (giftArray[e.target.id].splittable === false || giftArray[e.target.id].splittable === undefined) {

            const { value: split } = await Swal.fire({
                    title: `${user.email} has claimed this gift!`,
                    confirmButtonColor: '',
                    confirmButtonText: 'Proceed',
                    text: 'Allow others to split? (leave box unchecked if not)',
                    input: 'checkbox',
                    inputValue: 0
                })
                if (split) {
                    giftArray[e.target.id].splittable = true
                    // console.log(split, typeof split);
                    setSplittable(true)
                    updateClaimed()
                }
            }
            })
            .catch((error) => console.log(error))
        }
    const handleUnclaim = (e) => {
        let msg;
        switch
            (giftArray[e.target.id].splittable) {
                case true:
                    msg = '(This will remove all splittees)'
                    break;
                default:
                    msg = ''
            }
        return Swal.fire({
            title: 'You have this gift claimed, would you like to unclaim it?',
            html: `${msg}`,
            showCancelButton: true,
            confirmButtonColor: ''
        })
        .then((result) => {
            if (result.isConfirmed) {

                // const filterOutClaimee = claimeeArray[e.target.id].filter(i => i === user.email)
                // console.log(filterOutClaimee);

                giftArray[e.target.id].claimee = '' // this needs to change to an updated array filtering out the unclaimer
                giftArray[e.target.id].claimed = false
                giftArray[e.target.id].splittable = false
                giftArray[e.target.id].splittees = ''

                updateClaimed()
                setIsClaimed(false)
                setNotChecked(current => !current)
            } else {
                return
            }
            Swal.fire({
                title: `You, "${user.email}" have unclaimed this gift!`,
                confirmButtonColor: ''
            })
        })

    }
    const handleSplit = async (e) => {
        console.log(e.target.id);
        console.log(e.target);
        let num = parseInt(e.target.id.slice(5))
        console.log(num);
        const claimee = giftArray[num]?.claimee
        let existingSplittees = giftArray[num]?.splittees
        if (user.email === claimee) {
            return await Swal.fire({
                title: 'You are the initial item claimee. To remove the option to split with others, unclaim this item!',
                icon: 'warning',
                confirmButtonColor: '',
                confirmButtonText: 'Confirmed'
            })
        } else if (existingSplittees?.includes(user.email)) {
            const { value: remove } = await Swal.fire({
                title: 'You are already on the splittee list, would you like to remove yourself from this list?',
                confirmButtonColor: '',
                showCancelButton: true,
                input: 'checkbox',
                inputValue: 0,

            })
            if (remove === 1) {
                const filterYourselfOut = existingSplittees.filter(i => i !== user.email)
                console.log(filterYourselfOut);
                giftArray[num].splittees = filterYourselfOut
                updateClaimed();
            }
        } else {
            const { value: confirmSplit } = await Swal.fire({
                title: `${claimee} has claimed this item and is allowing for others to split.`,
                html: `Would you like to split this item with ${claimee} and potential others?`,
                confirmButtonColor: '',
                input: 'checkbox',
                inputValue: 0,
                showCancelButton: true

            })
            if (confirmSplit) {
                let arr = [];

                console.log(existingSplittees);
                if (existingSplittees !== undefined && existingSplittees !== '') {
                    arr.push(...giftArray[num]?.splittees, user.email)
                } else {
                    arr.push(user.email)
                }


                giftArray[num].splittees = arr
                console.log(giftArray[num]?.splittees);
                updateClaimed();
            }
        }
    }



    const handleToggle = () => {
        setToggle(prev => !prev)
    }

    const claimedInfo = async (claimee, splittees) => {
        // console.log(claimee);
        await Swal.fire({
            title: 'This will reveal who has claimed this item!',
            text: 'Are you sure you want to proceed?',
            confirmButtonColor: 'crimson',
            showCancelButton: true,
            cancelButtonColor: 'grey'
        })
        .then((result) => {
            if (result.isConfirmed && !claimee) {
                Swal.fire({
                    title: `This item has yet to be claimed!`,
                    confirmButtonColor: 'crimson'
                })
            } else if (result.isConfirmed) {
                Swal.fire({
                    title: `Item Claimee(s): ${splittees?.length >= 1 ? claimee + ', ' : claimee} ${splittees === undefined || splittees === '' ? '' : splittees?.map(i => i).join(', ')} `,
                    confirmButtonColor: 'crimson'
                })
            } else {
                return
            }
        })
    }
    const giftInfo = async (index) => {
        const giftInfo = giftArray[index].giftInfo
        const giftPrice = giftArray[index].giftCost

        const { value: text } = await Swal.fire({
            input: 'textarea',
            inputLabel: 'Item details e.g. size, color, quantity...',
            color: 'black',
            inputPlaceholder: 'Item Info...',
            html: `Current Gift Info: ${giftInfo ? `"${giftInfo}"` : '"None",'} \n Est. Price: ${giftPrice ? giftPrice : '"None entered."'}`,
            showCancelButton: true,
            cancelButtonColor: '',
            confirmButtonColor: ''
        })
        if (text) {

            giftArray[index].giftInfo = text
            // console.log(text);

            Swal.fire({
                title: 'Gift Information: ',
                text: `${text}`,
                confirmButtonColor: ''
            })
            .then(() => updateClaimed())
            .catch(err => err)
        } else {
            return
        }
    }
    const viewGiftInfo = (index) => {
        const giftInfo = giftArray[index].giftInfo
        const giftPrice = giftArray[index].giftCost

        Swal.fire({

            inputPlaceholder: 'Item Info...',
            html: `Current Gift Info: ${giftInfo ? `"${giftInfo}"` : '"None",'} \n Est. Price: ${giftPrice ? giftPrice : '"None entered."'}`,
            showCancelButton: true,
            cancelButtonColor: 'grey',
            confirmButtonColor: 'crimson'
        })
    }

    const formatGiftLink = (link) => {
        if (link.slice(0, 4).toLowerCase() === 'http') {
            return link
        } else {
            return 'https://' + link
        }
    }
    // console.log(giftArray.map(i => i?.username))
    // console.log(inFocus);

    return (
        <>
        <div className='giftContainer w-full flex items-center justify-center flex-col mb-24'>

            <table className='table table-zebra w-[98%] sm:w-3/4 border border-primary text-sm my-2' id="">
                <tbody>
                    {giftArray.length !== 0 && <tr className='border border-secondary ' id="">
                        <th className='font-bold'>Gift</th>
                        <th className='font-bold pl-0 pr-0 sm:pr-6'>Link</th>
                        <th className='font-bold  lg:pr-24'>User</th>

                        <th className='font-bold text-center' colSpan={2}>Info</th>
                    </tr>}
                    {giftArray && giftArray.map((i, index) =>
                        <tr key={i.giftName + inc++} className={"w-full"}>


                                <td className="text-sm sm:text-base flex flex-row items-center justify-center w-full">
                                    {user.email === i.requestor
                                    ? <div onClick={() => giftInfo(index)}><BsInfoCircle className='mr-1 text-info' size={'17px'} /></div>
                                    : <div onClick={() => viewGiftInfo(index)}><BsInfoCircle className='mr-1 text-info' size={'17px'} /></div>
                                    } {<p className='w-full' id={`${index}`}>{i.giftName} - {i?.giftCost}$</p>}

                                    <div className='editSpan ml-auto w-full' >
                                            {user.email === i.requestor && <GrEdit onClick={e => handleEdit(e, index)} className='w-6 h-6 ml-auto' />}
                                    </div>
                                </td>



                            <td className='giftLinkTd w-8 p-0 py-1'>{i.giftLink !== '' && <a rel="noopener noreferrer" href={formatGiftLink(i.giftLink)} target="_blank"><HiOutlineExternalLink className='text-info w-fit' size={'25px'} /></a>}</td>
                            <td className="requesteeTd w-12 p-0 py-1">
                                <div className='flex flex-col' id="imgDiv">
                                    <div className='text-xs sm:text-sm '>
                                    {
                                        user.email === i.requestor
                                        ? "You"
                                        : i?.username
                                        ? i.username
                                        : i.requestor.split('@')[0]
                                    }
                                    {/* { alternate plan^^^^^
                                        user.email === i.requestor
                                        ? !i.img && "You"
                                        : !i.img && i?.username.split('@')[0]
                                        ? !i.img && i.username.split('@')[0]
                                        : !i.img && i.requestor.split('@')[0]
                                    } */}
                                        </div>
                                    {
                                        i?.img ? <img className='w-12 h-12 rounded-full object-cover ml-2' src={i.img} /> : <img className='w-12 h-12 rounded-full object-cover ml-2' src={blankProfilePic} />
                                    }
                                </div>

                            </td>
                            <td className='cbtd w-4 p-0 py-1 pt-3'>
                                {
                                    user.email === i.requestor && toggle
                                    ? '?¿'
                                    : i.claimed && i.splittable
                                    ?  <><input className='cb checkbox checkbox-primary checkbox-md' id={index} type="checkbox" value={checked} disabled={i.claimee.includes(user.email) ? false : true} checked={i.claimed} onChange={e => handleUnclaim(e)} /><div className="splitLink w-16 z-50" onClick={e => handleSplit(e)} id={'split' + index}><MdCallSplit className='pointer-events-none w-6 h-6' color={'crimson'} /></div></>
                                    : i.claimed && !i.splittable
                                    ? <input className='cb checkbox checkbox-primary checkbox-md' id={index} type="checkbox" value={checked} disabled={i.claimee.includes(user.email) ? false : true} checked={i.claimed} onChange={e => handleUnclaim(e)} />
                                    : <input className='cb checkbox checkbox-primary checkbox-md' id={index} type="checkbox" value={notChecked} checked={i.claimed} onChange={e => handleClaim(e)} />
                                }

                            </td>
                            <td className='infoButton w-4 p-0 py-1'>{<a onClick={() => claimedInfo(i.claimee, i.splittees)} className=''><BsInfoCircle className='ml-auto text-info mr-1' size={'20px'} /></a>}</td>
                        </tr>
                    )}
                    {giftArray.length === 0 && !inFocus && (<tr className='my-2 py-4'><td className='border border-primary'><h2>Add a gift and its corresponding link using the inputs above to get started...</h2></td></tr>)}
                    {giftArray.length === 0 && inFocus && (<tr><td><h2 className='font-italic p-4 m-4 border border-error rounded-md border-dashed'>Nothing matches your search...</h2></td></tr>)}
                </tbody>
            </table>
            <span className={giftArray.length === 0 ? 'none' : 'thclaimed2 my-8 p-4 text-center'}>
                Click to reveal your items 'claimed' status
                <label className='switch mx-2'>
                    <input className='tog' value={toggle} type="checkbox" defaultChecked={true} onClick={handleToggle} />
                    <span className='slider round'></span>
                </label>
                {toggle ? "(hidden)" : "(revealed)"}
            </span>
        </div>

        </>
    )
}