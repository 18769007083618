import { useState, useRef, useEffect } from "react";
import { auth } from "../firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";


export default function ResetPassword() {

const inputRef = useRef();
const [email, setEmail] = useState('');
const [confirmationSuccess, setConfirmationSuccess] = useState('');
const [confirmationError, setConfirmationError] = useState('');
const [progressValue, setProgressValue] = useState(100);
const [startSuccess, setStartSuccess] = useState(false);
const [startError, setStartError] = useState(false);
const [completion, setCompletion] = useState(false)
const navigate = useNavigate();

const handleChange = e => {
    setEmail(e.target.value)
}

const countDown = () => {
    const timer = t => new Promise(res => setTimeout(res, t));
    timer(10000)
        .then(() => navigate('/'))
}



const handleSubmit = () => {

    sendPasswordResetEmail(auth, email)
    .then(() => {
        setConfirmationSuccess('If the specified email exists, an email was sent.');
        setStartError(false);
        setStartSuccess(true);
        countDown();
        // startCountDown();
        inputRef.current.value = '';


    }).catch((error) => {
        setStartError(true);
        // startCountDown();
        const errorCode = error.code;
        const errorMessage = error.message;
        setConfirmationError(error.message);
        // setConfirmationError('There was an error, double check the email provided!');

        console.log(errorCode);
        console.log(errorMessage);
    });
}

    return (
        <>
            <div className="flex flex-row items-center justify-center">
                <div className="flex flex-col">
                    <label htmlFor="resetemail">Enter your Email:</label>
                    <input className="input input-primary input-lg my-2" name="resetemail" type="email" placeholder="email@mail..." ref={inputRef} onChange={e => handleChange(e)} />
                    <div className="btn btn-secondary my-4 hover:bg-accent" onClick={handleSubmit}>Send Reset Email</div>
                    <div className={`toast toast-center toast-middle my-6 ${startSuccess ? '' : 'hidden'}`}>
                        <div className="alert alert-success">
                            <span>{confirmationSuccess}</span>
                        </div>
                        <div className={`alert alert-primary`}>
                            <span>Redirecting to login page in 10 seconds...</span>
                            {/* <progress className="progress progress-secondary w-56" value={progressValue} max="100"></progress> */}
                        </div>
                    </div>
                    <div className={`toast toast-center toast-middle my-6 ${startError ? '' : 'hidden'}`}>
                        <div className="alert alert-error">
                            <span>{confirmationError}</span>
                        </div>
                        <div className="alert alert-error  hidden items-center justify-center">
                            {/* <progress className="progress progress-warning w-56" value={progressValue} max="100"></progress> */}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}