import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineDashboard } from 'react-icons/md'
import './redirectpage.css'
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';


export default () => {

    const navigate = useNavigate()

    const takeMeBack = () => {
        onAuthStateChanged(auth, user => {
            if (user) {
                navigate('/dashboard', {replace:true})
            } else {
                navigate('/', {replace:true})
            }
        })
    }

    return (
        <>
            <h3 className='title' style={{textAlign: 'center'}}>This page does not exist!</h3>
            <div className='exclamation'>!</div>
            <hr />
            <div style={{textAlign:'center'}}><MdOutlineDashboard className='dash' onClick={takeMeBack} size={'200px'} color={'pink'} /><h3>Go back to your dashboard</h3></div>
            <hr />
            {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><PrevPage /></div>
            <h3 style={{textAlign: 'center'}}>Go back one page</h3> */}

        </>
    )
}