import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { BsInfoCircle } from 'react-icons/bs';
import Swal from 'sweetalert2';
import './events.css'
import { useEffect } from 'react';
import { Suspense } from 'react';



export default ({ data }) => {
    // console.log(data);
    const [loading, setLoading] = useState(true)
    const [hasLoaded, setHasLoaded] = useState(false)

    let inc = 0;

    const showEventInfo = (index) => {
        let eventInfo = data[index].events
        Swal.fire({
            title: '<span style="font-style: italic;"><b>Event Details</b></span>',
            html: `<b>Name:</b> ${eventInfo.eventName} <p><b>Date:</b> ${eventInfo.eventDate.slice(5, 10) + '-' + eventInfo.eventDate.slice(0, 4)}</p> <p><b>Creator:</b> ${eventInfo.eventOwner}</p>`,
            confirmButtonColor: 'crimson'
        })
    }

    function TestComponent()
    {
        if (!loading) {
            setTimeout(() => {
                setHasLoaded(true)
            }, 1750);
        }
        return (
            <>
              {!hasLoaded ? <tr colSpan={2}><td align='center' className='text-xl font-bold italic'><span className={"loading loading-spinner"}></span>Checking for Events...</td></tr>
              : !data.length && (<tr colSpan={'2'}><td style={{ display: 'flex', justifyContent:'center', alignItems:'center'}}><h2 style={{marginTop:'0px', marginBottom:'0px', fontStyle: 'italic'}}>Add an event to view here...</h2></td></tr>)}
            </>
        )
    }

    useEffect(() => {
        setLoading(false)
    }, [data])




    return (
        <>
        <div className='flex items-center justify-center w-full'>
            <table className='table table-zebra overflow-x-hidden w-[95%] sm:w-3/4 border border-primary mb-8 px-2'>
            {hasLoaded && data.length > 0 && <thead>
                <tr>
                    <th className='text-xl'>Your Events</th>
                    <th className='text-xl text-right'>Event Info</th>
                </tr>
            </thead>
            }
                <tbody>


                {hasLoaded && data?.map((i, index) =>
                (
                    <tr key={i.id + inc++} className={''}>
                        <td className="text-2xl"><Link className='text-primary underline hover:text-secondary' to={`/dashboard/event/${i.id}`}>{!i.events.eventName.includes(" ") && i.events?.eventName.length > 20 ? i.events.eventName.slice(0, 13) + '...' : i.events.eventName}</Link><br /><span className='text-sm sm:text-lg ml-2'>{i.events?.eventDate.split('-')[1] + '-' + i.events?.eventDate.split('-')[2] + '-' + i.events?.eventDate.split('-')[0]}</span></td>

                        <td className="flex items-center justify-center ml-auto pr-16" width={10}><Link className='text-info' to={`/dashboard/eventinfo/${i.id}`}><BsInfoCircle size={'25px'}  /></Link></td>
                    </tr>
                ))}
                <Suspense fallback={<tr><td>Loading...</td></tr>}>
                    <TestComponent />
                {/* {data.length === 0 && (<tr colSpan={'2'}><td style={{ display: 'flex', justifyContent:'center', alignItems:'center'}}><h2 style={{marginTop:'0px', marginBottom:'0px', fontStyle: 'italic'}}>Add an event to view here...</h2></td></tr>)} */}
                </Suspense>
                </tbody>
            </table>
        </div>
        </>
    )
}



// <td id="hostedTd">{i.events?.eventOwner}</td>
// <td id="dateTd">{i.events?.eventDate?.slice(5, 10) + '-' + i.events?.eventDate?.slice(0, 4)}</td>