import './about.css';



export default () => {
    return (
        <>
            <div className="aboutTainer">
                <h1 className="mission text-xl">The Mission</h1>
                <h5>To simplify the gift exchange process for holidays and events.</h5>
                <h1 className="mission text-xl">How?</h1>
                <h5>Consolidating event coordination/planning/communicating into one central location.</h5>
                {/* <h1 className="mission">Guidelines:</h1> */}
            </div>
        </>
    )
}