// import './App.css';
import Navbar from './components/Navbar';
import { Outlet } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from './firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AuthContext } from './components/context/AuthContext';
import CookieCrumbs from './components/cookiecrumbs/CookieCrumbs';






function App() {
console.log('%c~ Updated: 01 26 2024 ~', 'color:black; font-style: italic; font-weight: bold; font-size: 24px; border: 2px solid white; padding: 4px; border-radius: 5px; background: linear-gradient(to left, pink, whitesmoke)');



  const location = useLocation();
  const navigate = useNavigate();




  useEffect(() => {
    // if (id !== to userEvents) { redirect to 'an event with this id does not exist' page?}


  }, [])


  const { dispatch } = useContext(AuthContext)


  const logOut = async () => {

    await signOut(auth).then(() => {
      console.log('Successfully logged out!');

      dispatch({ type: 'LOGOUT' })

      navigate('/departure')
    })
    .catch((error) => console.log(error))
  }

  if (location.pathname === '/logout') logOut()

  return (
    <>
      <Navbar />
      <CookieCrumbs />
      <div className='content-container'> {/*containers for bg's */}
        <div className='content-container2'>


          <Outlet />
        </div>
      </div>
    </>
  );
}

export default App;
